<script setup>
import { storeToRefs } from 'pinia';
import { useForm } from 'vee-validate';
import { useAuthStore } from '~/stores/auth';
import { useMessageStore } from '~/stores/messages';

// Fetch form config
const { getUserPreferences } = await useFormFieldConfigs();

// Fetch messages
const messagesStore = useMessageStore();
const { messages: staticKeys } = storeToRefs(messagesStore);

// Load user data
const authStore = useAuthStore();
const { handleUserData } = authStore;
const { statusObj, currentUser } = storeToRefs(authStore);

// Prepare form //
const { values, meta, handleSubmit, isSubmitting } = useForm({
    preferences: ''
    // newsletter1: '',
    // newsletter2: ''
});

// Add dynamic values
const getPreferences = computed(() => currentUser.value?.preferences?.interests || []);
// const getNewsletter1 = computed(() => [] || currentUser.value?.preferences?.newsletters); // currentUser?.preferences.newsletters
// const getNewsletter2 = computed(() => [] || currentUser.value?.preferences?.newsletters);

// Set initial state
const initialState = ref({});

watch(() => currentUser.value, () => {
    setInitialFormdata({
        // newsletter1: getNewsletter1.value,
        // newsletter2: getNewsletter2.value,
        preferences: getPreferences.value
    }, initialState);
});

setInitialFormdata({
    // newsletter1: getNewsletter1.value,
    // newsletter2: getNewsletter2.value,
    preferences: getPreferences.value
}, initialState);

const isVisible = computed(() => {
    if (values) {
        const differentData = !objAreEqual(
            values,
            initialState.value
        )

        // Temporarily add check that prefs may not be empty
        // Show button at least success message is visible
        return (differentData && meta.value?.valid && values.preferences.length > 0) || statusObj.value?.success;
    }

    return false;
});

// Fetch preferences
const preferences = await useFetchConsumerPreferences();

// Define config for form elements
const userPreferences = getUserPreferences(preferences.value?.preferences);

/* const newsletterConfig = ref({
  nl1: {
    fieldType: 'checkbox',
    label: 'Newsletter 1',
    id: 'nl1',
    value: 'nl1',
    name: 'newsletter1',
    val: getNewsletter1
  },
  nl2: {
    fieldType: 'checkbox',
    label: 'Newsletter 2',
    id: 'nl2',
    value: 'nl2',
    name: 'newsletter2',
    val: getNewsletter2
  }
}); */

const isFormSubmitting = computed(() => isSubmitting.value || statusObj.value?.success);

const buttonConfig = ref({
    label: staticKeys.value?.account_form_button_label_savechanges,
    fieldType: 'submit',
    active: true,
    visible: isVisible,
    isPending: isFormSubmitting
});

const getFormClass = computed(() => {
    return {
        'is-submitting': isFormSubmitting.value
    }
})

const onSubmit = handleSubmit(async (values) => {
    if (values) {
        /*
        payload: {
                preferences: {
                    interests: tempPrefValues,
                    newsletters: [] // "CGI_AEZ"
                }
            },
        */

        const payload = values.preferences.length > 0
            ? {
                preferences: {
                    interests: values.preferences
                }
            }
            : {}

        await handleUserData({
            method: 'PATCH',
            payload,
            successMessage: staticKeys.value?.account_form_settings_success_message,
            scrollDiv: document.querySelector('.sidebar-content-inner')
        });
    }
});
</script>

<template>
    <SideBarWrapper>
        <FormMessage :status-obj="statusObj" />
        <SideBarHeader>
            <SideBarMainHeadline :text="staticKeys?.menu_preferences_markdown" />
        </SideBarHeader>

        <form class="account-preferences-form" :class="getFormClass" @submit.prevent="onSubmit">
            <h3 class="sidebar-headline">{{ staticKeys?.account_menu_preferences_headline }}</h3>

            <FormCheckBoxGroup>
                <FormCheckBox
                    v-for="item in userPreferences"
                    :key="item.id"
                    :config="{ ...item, ...{val: getPreferences}, ...{pending: isSubmitting}}"
                />
            </FormCheckBoxGroup>
            <!--
         <h3 class="sidebar-headline">{{ staticKeys?.account_menu_newsletter_headline }}</h3>

                     <FormSection>
                         <FormCheckBoxGroup>
                             <FormCheckBox :config="newsletterConfig?.nl1" />
                             <FormCheckBox :config="newsletterConfig?.nl2" />
                         </FormCheckBoxGroup>
                     </FormSection>
         -->

            <FormButton :config="buttonConfig" />
        </form>
    </SideBarWrapper>
</template>

<style lang="scss">
.account-preferences-form {
    &::after {
        content: '';
        transition: opacity 400ms ease;
        opacity: 0;
    }

    &.is-submitting {
        @include form-is-submitting;
    }

    // Temporary
    .btn-primary {
        margin-top: 30px;
    }
}
</style>
